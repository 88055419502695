import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let logoWallSchema = object().shape({
  image_and_links: array().nullable(),
  reference: object().nullable(),
  style: string().nullable(),
  heading: string().nullable(),
  subheading: string().nullable(),
  __digest: string().required(),
});

// @todo: image options from media query should be determined
// @todo: how to recover gracefully from a typeerror?
export const transformLogoWallData = (data) => {
  console.log("transformLogoWallData", data);
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      image_and_links: null,
      style: null,
      heading: null,
      subheading: null,
      reference: null,
      enabled: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.subheading = data.subheading;
    hydratedModel.heading = data.heading;
    hydratedModel.style = data.logo_wall_style_options;
    hydratedModel.reference = data.reference;
    hydratedModel.image_and_links = data.image_and_links
      ? data.image_and_links
      : null;
    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      logoWallSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
